import React from "react"
import Header from "./header"
import "../styles/styles.scss"
import Footer from "./footer"
import { graphql, StaticQuery } from "gatsby"

const Layout = ({ children }) => (
  <StaticQuery
    query={query}
    render={data => (
      <div id="site-wrapper">
      <Header navItems={data.allKontentItemContentHome.nodes[0].elements.subpages.value}/>
      <section className="content-container">
          {children}
      </section>
      <Footer />
    </div>
    )}
  />
)

export default Layout

export const query = graphql`
query navItems   {
  allKontentItemContentHome {
    nodes {
      elements {
        subpages {
          value {
            ... on kontent_item_content___standard {
              elements {
                slug {
                  value
                }
                metadata__title {
                  value
                }
                subpages {
                  value {
                    ... on kontent_item_content___standard {
                      elements {
                        slug {
                          value
                        }
                        metadata__title {
                          value
                        }
                      }
                    }
                  }
                }
              }
            }
            ... on kontent_item_content___news_listing {
              elements {
                slug {
                  value
                }
                metadata__title {
                  value
                }
                subpages {
                  value {
                    ... on kontent_item_content___standard {
                      elements {
                        slug {
                          value
                        }
                        metadata__title {
                          value
                        }
                      }
                    }
                  }
                }
              }
            }
            ... on kontent_item_content___events_listing {
              elements {
                slug {
                  value
                }
                metadata__title {
                  value
                }
                subpages {
                  value {
                    ... on kontent_item_content___standard {
                      elements {
                        slug {
                          value
                        }
                        metadata__title {
                          value
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}`