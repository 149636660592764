import React from "react"
import ContactUs from "./contact-us"
import FooterNavigation from "./footer-navigation";
import { Link } from "gatsby"
import logo from "../../src/assets/primary-logo.png"

const Footer = () => {
    return (
        <footer>
            <div className="f-logo">
            <Link to="/">
                <img src={logo} alt="UK Endorsement Board" />
            </Link>
            </div>
            <section className="f-container">
            <div className="f-left-content">
            <ContactUs />
            </div>
            <div className="f-right-content">
            <h3>Stay up to date</h3>
            <p>To stay up to date on UKEB news and events, subscribe by emailing <a href="mailto:contact@endorsement-board.uk?subject=SUBSCRIBE">contact@endorsement-board.uk</a> and putting SUBSCRIBE in the subject line.</p>
            <p>Your personal information submitted via this email will be processed in accordance with our <Link to="/privacy-policy">Privacy Policy</Link>.</p>
            <p>If you would like to unsubscribe, please <a href="mailto:contact@endorsement-board.uk?subject=UNSUBSCRIBE">email us</a></p>
            </div>
            </section>
            <FooterNavigation />
            <section className="f-bottom-content">
            <p className="f-text-legal">© {new Date().getFullYear()} The UK Accounting Standards Endorsement Board</p>
            </section>
        </footer>
      );
}

export default Footer
