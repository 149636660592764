import React from "react"
import "../styles/contact-us.scss"

const ContactUs = () => {
    return(
        <>
        <h3 className="cu-heading">Contact Us</h3>
        <address className="cu-address">
            UKEB<br/>
            6th Floor<br/>
            10 South Colonnade<br/>
            London<br/>
            E14 4PU
        </address>
        <p className="cu-email">Email: <a href="mailto:UKEndorsementBoard@endorsement-board.uk">UKEndorsementBoard@endorsement-board.uk</a></p>
      </>
    )
}

export default ContactUs