import React from "react"
import Layout from "../components/layout"
import Head from "../components/head"
import { graphql } from "gatsby"
import Metadata from "./metadata"
import "../styles/templates/standard-content.scss"

const Standard = ({ data }) => {
const item = data.kontentItemContentStandard.elements
const allDocuments = data.allKontentItemComponentDocument.nodes
const pageLinkedDocuments = item.documents.value

const sidebarContent = <div className="sc-side"><aside className="rta-side" dangerouslySetInnerHTML={{ __html: item.sidebar_copy.value }} /></div>
let sidebarPositionCheck = item.sidebar_position.value.length
let sidebarAboveContent = ""
let sidebarBelowContent = ""
let sidebarPosition = ""
let scWrapperClass = "sc-wrapper"

if (sidebarPositionCheck > 0) {
  sidebarPosition = item.sidebar_position.value[0].name

  if (sidebarPosition === "above-content") {
    sidebarAboveContent = sidebarContent
    scWrapperClass = "sc-wrapper sc-wrapper__above"
  }
  else if (sidebarPosition === "below-content") {
    sidebarBelowContent = sidebarContent
  }
}

const pageDocuments = pageLinkedDocuments.map(({ id }, index) => {
  const documentItem = allDocuments.find(item => item.id === id)
  if (documentItem) {
    return (
      <div key={index} className="sc-table-row" role="row">
      <div className="sc-table-cell" role="rowheader"><a href={documentItem.elements.document.value[0].url} rel="noreferrer">{documentItem.elements.title.value}</a></div>
      <div className="sc-table-cell" role="gridcell">{documentItem.elements.date.value}</div>
      <div className="sc-table-cell" role="gridcell">{documentItem.elements.size.value}</div>
      <div className="sc-table-cell sc-table-cell__document" role="gridcell">
        {documentItem.elements.type.value[0].name}
        <span aria-label={documentItem.elements.type.value[0].name} className="sc-document-icon material-icons">insert_drive_file</span>
      </div>
    </div>
    )
  }
  return ""
})

const documentContainer = (
  <div className="sc-table-container" role="grid">
    <div className="sc-table-row" role="row">
      <div className="sc-table-cell" role="columnheader">Title</div>
      <div className="sc-table-cell" role="columnheader">Date</div>
      <div className="sc-table-cell" role="columnheader">Size</div>
      <div className="sc-table-cell" role="columnheader">Document type</div>
    </div>
    {pageDocuments}
  </div>
)

return (

  <Layout>
    <Metadata pageData={item} siteData={data.site.siteMetadata} />
    <Head />
    <h1 className="sc-heading">{item.heading.value}</h1>
    <div className={scWrapperClass}>
    {sidebarAboveContent}
    <div className="sc-body-container">
    <section className="rta-body" dangerouslySetInnerHTML={{ __html: item.body_copy.value }} />
    {/* render the OneTrust cookie list if the item.heading.value is Cookie Policy */}
    {item.heading.value === 'Cookie Notice' && <div id="ot-sdk-cookie-policy"></div>}
    {
      pageDocuments.length > 0
      ? documentContainer
      : ""
    }
    <section className="rta-body" dangerouslySetInnerHTML={{ __html: item.additional_copy.value }} />
    </div>
    {sidebarBelowContent}
    </div>
  </Layout>
  )
}

export default Standard

export const query = graphql`
  query standardQuery($slug: String!) {
    kontentItemContentStandard(fields: { slug: { eq: $slug } }) {
      elements {
        heading {
          value
        }
        body_copy {
          value
        }
        documents {
          value {
            ... on kontent_item_component___document {
              id
            }
          }
        }
        additional_copy {
          value
        }
        slug {
          value
        }
        sidebar_copy {
          value
        }
        sidebar_position {
          value {
            name
          }
        }
        metadata__title {
          value
        }
        metadata__description {
          value
        }
        metadata__image {
          value {
            url
          }
        }
      }
    }
    site {
        siteMetadata {
            lang
            siteUrl
            websiteTitle
            description
        }
    }
    allKontentItemComponentDocument {
      nodes {
        id
        elements {
          date {
            value(formatString: "DD MMMM YYYY")
          }
          document {
            value {
              url
            }
          }
          size {
            value
          }
          title {
            value
          }
          type {
            value {
              name
            }
          }
        }
      }
    }
  }
`
