import React from "react";
import { Link } from "gatsby"
import logo from "../../src/assets/primary-logo.png"
import NavBarDesktop from "./navbar-desktop"
import NavBarMobile from "./navbar-mobile"

function Header(props) {
  return ( 
    <>
    <header className="desktop-header">
      <div className="header-bar">
        <Link to="/" className="logo" aria-label="Visit the home page (opens in same tab)">
          <img src={logo} alt="UK Endorsement Board" />
        </Link>
        <Link to="/uk-adopted-international-accounting-standards" className="button">UK-Adopted International Accounting Standards</Link>
        <Link to="/adoption-status-report" className="button">Adoption Status Report</Link>
      </div>
      <div className="breaker-bar"></div>
      <NavBarDesktop navItems={props.navItems}/>
    </header>
    <header className="mobile-header">
      <div className="header-container">
        <div className="header-bar">
          <Link to="/" className="logo">
            <img src={logo} alt="UK Endorsement Board" />
          </Link>
          <NavBarMobile navItems={props.navItems}/>
        </div>
      </div>
      <div className="breaker-bar"></div>
      <div className="mobile-nav-container">
        <Link to="/uk-adopted-international-accounting-standards" className="button">UK-Adopted International Accounting Standards</Link>
        <Link to="/adoption-status-report" className="button">Adoption Status Report</Link>
      </div>
  </header>
  </>
  )
}

export default Header
