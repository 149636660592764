import React from "react";
import Helmet from "react-helmet"
import logo from "../../src/assets/primary-logo.png"

const Metadata = ({ pageData, siteData }) => {
let pageImage = ""
let pageImageItems = pageData.metadata__image.value.length
if (pageImageItems === 0) {
  pageImage = siteData.siteUrl + logo
}
else {
  pageImage = pageData.metadata__image.value[0].url
}

if (pageData.slug.value === "/") {
    pageData.slug.value = ""
}

return (
    <Helmet>
        <title>{pageData.metadata__title.value + " | "} {siteData.websiteTitle}</title>
        <meta name="description" content={pageData.metadata__description.value} />
        <meta name="image" content={pageImage} />
        <link rel="canonical" href={siteData.siteUrl + "/" + pageData.slug.value} />

        <meta property="og:title" content={pageData.metadata__title.value + " | " + siteData.websiteTitle} />
        <meta property="og:type" content="Website" />
        <meta property="og:url" content={siteData.siteUrl + "/" + pageData.slug.value} />
        <meta property="og:image" content={pageImage} />
        <meta property="og:description" content={pageData.metadata__description.value} />
    </Helmet>
    )
}

export default Metadata
