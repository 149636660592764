import React, { useEffect, useState } from 'react'
import "../styles/styles.scss"
import { Link } from "gatsby"

function NavBarMobile(props) {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    const primaryNavList = document.querySelectorAll(".primary-nav-item-container")

    primaryNavList.forEach((primaryItem) => {
      primaryItem.addEventListener("click", (e) => {
        primaryItem.classList.toggle("nav-open")
      })
    })
  }, []);

  const secondaryNavigation = (item, subItem) => <li className="primary-nav-item-mobile">
    <div className="primary-nav-item-container">
      <Link to={`/${item.elements.slug.value}`}>{item.elements.metadata__title.value}</Link>
      <span className="mobile-nav-open material-icons">add</span>
      <span className="mobile-nav-close material-icons">remove</span>
    </div>
    <ul className="secondary-nav-mobile">
      {subItem.map((item, index) =>
        <li key={index} className="secondary-nav-item-mobile"><Link to={`/${item.elements.slug.value}`}>{item.elements.metadata__title.value}</Link></li>
      )}
    </ul>
  </li>

  const navigation = props.navItems.map((item, index) => item.elements.subpages.value.length === 0 ?
    <li key={index} className="primary-nav-item-mobile"><div className="primary-nav-item-container"><Link to={`/${item.elements.slug.value}`}>{item.elements.metadata__title.value}</Link></div></li>
    : secondaryNavigation(item, item.elements.subpages.value))

  const openNav = () => {
    setOpen(!open)
    const contentContainer = document.querySelector(".content-container")
    contentContainer.classList.toggle("nav-open-apply-overlay")
  }

  return (
    <div className="nav-wrap">
      <nav className="nav-bar-mobile">
        <div className="burger-container">
          <span role="button" onClick={() => openNav()} onKeyDown={() => openNav()} tabIndex={0} className="material-icons mobile-menu">
            {open ? "clear" : "menu"}
          </span>
        </div>
        <ul className="nav-links-mobile" style={open ? { display: 'block' } : {}}>
          <li className="primary-nav-item-mobile"><div className="primary-nav-item-container"><Link to="/">Home</Link></div></li>
          {navigation}
        </ul>
      </nav>
    </div>
  )
}

export default NavBarMobile
