import React from "react"
import "../styles/footer-navigation.scss"
import { Link } from "gatsby"

const FooterNavigation = () => {

    return (  
        <div className="fn-nav-container">
        <ul className="fn-list">
            <li><Link to="/privacy-policy">Privacy & UKEB</Link></li>
            <li><a href="/cookie-notice">Cookies</a></li> {/* the cookie-policy page has a late loaded OneTrust link which requies a full page load so cant use Link */}
            <li><Link to="/terms-of-use">Terms of Use</Link></li>
            <li><Link to="/freedom-of-information-policy">Freedom of Information Policy</Link></li>
        </ul>
        </div>
    )
}

export default FooterNavigation