import * as React from "react"
import Helmet from "react-helmet"

const Head = () => {
  return (

    <Helmet>
      <html lang="en" />
      <script src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js" defer data-document-language="true" type="text/javascript" charSet="UTF-8" data-domain-script="dff87caa-17c1-44ae-b2e0-25fe9c003452" ></script>
      <script type="text/javascript" dangerouslySetInnerHTML={{
        __html: `
                function OptanonWrapper() { }
              `,
      }} />
    </Helmet>
  );
}

export default Head
