import React from "react"
import { Link } from "gatsby"

function NavBarDesktop(props) {

  const secondaryNavigation = (item) => (
    <>
      <div className="nav-arrow"><span aria-hidden={true} className="material-icons">arrow_drop_up</span></div>
      <ul className="secondary-nav">
        <div className="nav-elements-container">
          {item.elements.subpages.value.map((item, index) =>
            <li key={index} className="secondary-nav-item"><Link activeClassName="active-menu" to={`/${item.elements.slug.value}`}>{item.elements.metadata__title.value}</Link></li>
          )}
        </div>
      </ul>
    </>
  )

  const navigation = props.navItems.map((item, index) =>
    <li key={index} className="primary-nav-item">
      <Link to={`/${item.elements.slug.value}`} activeClassName="active-menu" partiallyActive={true}>{item.elements.metadata__title.value}</Link>
      {item.elements.subpages.value.length !== 0 ? secondaryNavigation(item) : ""}
    </li>
  )

  return (
    <nav className="nav-bar">
      <ul className="nav-links">
        <div className="nav-elements-container">
          <li className="primary-nav-item"><Link to="/">Home</Link></li>
          {navigation}
        </div>
      </ul>
    </nav>
  )
}

export default NavBarDesktop